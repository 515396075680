import React, { useEffect, useState, lazy } from 'react';
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import backgroundImage from '../assets/falsebanner.jpg';
import { IoLocationSharp } from "react-icons/io5";
import { IoMailSharp } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { FaFacebookF, FaInstagram, FaTwitter, FaPinterestP } from 'react-icons/fa'
import { IoIosSend } from "react-icons/io";
import banner8 from '../assets/false1.jpg';
import banner7 from '../assets/false2.jpg';
import banner9 from '../assets/false3.jpg';
import banner10 from '../assets/false9.jpg';
import banner11 from '../assets/false5.jpg';
import banner12 from '../assets/false6.jpg';
import banner13 from '../assets/false7.jpg';
import banner14 from '../assets/false8.jpg';
import banner15 from '../assets/false9.jpg';
import banner16 from '../assets/false10.jpg';
import banner17 from '../assets/false11.jpg';
import banner18 from '../assets/false12.jpg';
import banner19 from '../assets/false13.jpg';
import banner20 from '../assets/false14.jpg';
import banner21 from '../assets/false15.jpg';
import banner22 from '../assets/false16.jpg';
import banner23 from '../assets/false17.jpg';
import banner24 from '../assets/false18.jpg';
import banner25 from '../assets/false19.jpg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';





function FallCeiling() {
    return (
        <>
            <Header />
            <ModularBanner />
            <ModularSection />
            <Footer />
        </>

    )
}
function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false); // To toggle Category dropdown
  
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
  
    const toggleCategory = () => {
        setCategoryOpen(!categoryOpen); // Toggle category dropdown
    };
    return (
        <header className="py-5 bg-white shadow-md">
            <div className="flex items-center justify-between px-5 py-4 mx-auto lg:px-28">
                <div className="w-2/12 max-sm:w-[40vw]">
                    {/* Logo Section */}
                    <img src={logo} alt="Logo" className="h-12 lg:h-16" />
                </div>
  
                {/* Desktop Navigation Section */}
                <nav className="hidden w-10/12 px-20 py-8 space-x-6 font-semibold text-white bg-black md:flex">
                    <Link to="/" className="hover:text-[#8c2c2c]">Home</Link>
                    <Link to="/about" className="hover:text-[#8c2c2c]">About</Link>
  
                    {/* Category with Submenu and Dropdown Icon */}
                    <div className="relative group">
                        <button className="menu-link hover:text-[#8c2c2c] flex items-center">
                            Category
                           
                        </button>
                        <div className="absolute hidden text-white bg-black shadow-lg submenu group-hover:block">
                            <Link to="/modular-kitchen" className="font-sans font-semibold text-white submenu-item hover:bg-black hover:text-white">Modular Kitchen</Link>
                            <Link to="/Wardrobe" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wardrobe</Link>
                            <Link to="/pooja-room" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Pooja Room</Link>
                            <Link to="/tv-panel" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Tv Panel</Link>
                            <Link to="/wall-panel" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wall Panel</Link>
                            <Link to="/wall-shelves" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wall Shelves</Link>
                            <Link to="/show-case" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Show Case</Link>
                            <Link to="/false-ceiling" className="text-gray-700 submenu-item hover:bg-black hover:text-white">False Ceiling</Link>
                            <Link to="/office-interior" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Office Interior</Link>
                        </div>
                    </div>
                    <Link to="/testimonials" className="hover:text-[#8c2c2c]">Testimonials</Link>
                    <Link to="/contact" className="hover:text-[#8c2c2c]">Contact</Link>
  
                    {/* Social Media Links */}
                    <div className="flex-grow"></div>
                    <div className="flex items-center space-x-3">
                        <a href="https://www.facebook.com/elegantkitchencbe" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://www.instagram.com/elegantkitchencbe/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://x.com/Janarth01930131" target="_blank" rel="noopener noreferrer">
                            <FaTwitter className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://www.pinterest.com/elegantkitchenkovai/" target="_blank" rel="noopener noreferrer">
                            <FaPinterestP className="text-white hover:text-[#8c2c2c]" />
                        </a>
                    </div>
                </nav>
  
                {/* Mobile Menu Button */}
                <button
                    onClick={toggleMenu}
                    className="p-2 text-black md:hidden lg:text-white">
                    ☰
                </button>
            </div>
  
            {/* Mobile Navigation */}
            {menuOpen && (
                <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full p-4 text-white bg-black md:hidden">
                    {/* Close Button */}
                    <button
                        onClick={toggleMenu}
                        className="absolute text-3xl text-white top-5 right-5"
                    >
                        ✖
                    </button>
                    <nav className="flex flex-col space-y-6 text-xl text-center">
                        <Link to="/" className="hover:text-gray-400" onClick={toggleMenu}>Home</Link>
                        <Link to="/about" className="hover:text-gray-400" onClick={toggleMenu}>About</Link>
  
                        {/* Category Dropdown in Mobile View */}
                        <div className="relative">
                            <button
                                className="flex items-center justify-center hover:text-gray-400"
                                onClick={toggleCategory}>
                                Category
                                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                            </button>
                            {categoryOpen && (
                                <div className="mt-2 bg-white items-left">
                                    <Link to="/modular-kitchen" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Modular Kitchen</Link>
                                    <Link to="/Wardrobe" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wardrobe</Link>
                                    <Link to="/pooja-room" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Pooja Room</Link>
                                    <Link to="/tv-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Tv Panel</Link>
                                    <Link to="/wall-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Panel</Link>
                                    <Link to="/wall-shelves" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Shelves</Link>
                                    <Link to="/show-case" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Show Case</Link>
                                    <Link to="/false-ceiling" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>False Ceiling</Link>
                                    <Link to="/office-interior" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Office Interior</Link>
                                </div>
                            )}
                        </div>
  
                        <Link to="/testimonials" className="hover:text-gray-400" onClick={toggleMenu}>Testimonials</Link>
                        <Link to="/contact" className="hover:text-gray-400" onClick={toggleMenu}>Contact</Link>
                    </nav>
                </div>
            )}
        </header>
    );
  }
 
function ModularBanner() {
    return (
        <section className="relative bg-center bg-cover object-fit brightness-125 h-96" style={{ backgroundImage: `url(${backgroundImage})` }} >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="container relative flex flex-col justify-center h-full mx-auto text-left items-left">
                <h1 className="text-5xl font-bold text-left text-white">FALSE CEILING DESIGN</h1>
                <p className="mt-4 text-white">
                    <a href="/" className="hover:underline">Home</a> &gt; False Ceiling
                </p>
            </div>
        </section>
    );
}
function ModularSection() {
    return (
        <div className="container px-4 mx-auto my-16">
            {/* Full-width Heading and Subheading */}
            <div className="w-full py-5 mb-10 text-center ">
                <span className="font-sans font-semibold text-[#8c2c2c] underline underline-offset-8 ">Elegant Kitchen</span>
                <h2 className="mt-3 mb-2 text-4xl font-bold">False Ceiling </h2>
                <p className="py-10 mb-6 font-sans font-medium text-gray-600">
                Enhance your interiors with our expertly crafted false ceiling designs. Custom-designed to suit your style and space, our false ceilings add a touch of elegance while also improving acoustics and lighting. Whether you prefer Modern False Ceilings, LED-Illuminated Ceilings, or Gypsum Board Designs, 'Elegant Kitchen' offers a variety of options to elevate your home.
                    </p>    </div>

            {/* Main Content Section */}
            <div className="flex flex-col items-center gap-8 p-0 pt-10 lg:flex-row">
                {/* Left Image */}
                <div className="relative w-full lg:w-1/2">
                    {/* <hr className="absolute -top-20 left-30 w-[20%] border-t border-gray-300" /> */}

                    <img
                        src={banner8}
                        alt="Modern Building"
                        className="object-cover w-full p-4 -mt-32 border rounded-md"
                    /><br></br><br></br>
                    <img
                        src={banner7}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    <br></br><br></br>
                    <img
                        src={banner11}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    <br></br><br></br>
                    <img
                        src={banner12}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    <br></br><br></br>
                    <img
                        src={banner15}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    
                    <br></br><br></br>
                    <img
                        src={banner16}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    
                    <br></br><br></br>
                    <img
                        src={banner17}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    
                    <br></br><br></br>
                    <img
                        src={banner18}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    
                    


                </div>

                {/* Right Content */}
                <div className="w-full lg:w-1/2">
                    {/* <h2 className="mb-2 font-sans text-2xl font-semibold">Modular Kitchens Design</h2> */}
                    {/* <a
          href="#"
          className="inline-block px-8 py-3 mb-8 text-white transition duration-300 bg-black rounded-md hover:bg-gray-800"
        >
          Read More
        </a> */}
                    {/* Bottom Image Below Button */}
                    <img
                        src={banner9}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    /><br></br><br></br>
                    <img
                        src={banner10}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    <br></br><br></br>
                    <img
                        src={banner13}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    <br></br><br></br>
                    <img
                        src={banner14}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    <br></br><br></br>
                    <img
                        src={banner19}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    
                    <br></br><br></br>
                    <img
                        src={banner20}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    
                    <br></br><br></br>
                    <img
                        src={banner21}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    
                    <br></br><br></br>
                    <img
                        src={banner22}
                        alt="Interior Design"
                        className="object-cover w-full p-4 border rounded-md "
                    />
                    
                    
                    




                </div>

            </div>

        </div>
    );
};
function Footer() {
    return (
        <div>

            {/* Footer Section */}
            <footer className="text-white bg-black ">
                <div className="grid grid-cols-1 gap-8 pt-10 mx-10 lg:grid-cols-4 md:grid-cols-3">
                    {/* Column 1: Logo and Description */}
                    <div>
                        <img src={logo} alt="Logo" className="h-20 lg:mr-24 md:mr-4 " />
                        <p className='pt-5 font-sans font-medium'>We welcome you to our wonderful world of Interior and Furniture.</p>
                    </div>

                    {/* Column 2: Pages Links */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Useful Links</h4>
                        <ul>
                            <li><a href="#" className="pb-10 font-sans font-normal hover:underline ">About Us</a></li><br></br>
                            <li><a href="#" className="pb-2 font-sans font-normal hover:underline">Testimonial</a></li><br></br>
                            <li><a href="/contact" className="pb-2 font-sans font-normal hover:underline">Contact</a></li>
                        </ul>
                    </div>

                    {/* Column 3: Office Information */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Contact Us</h4>
                        <ul>
                            <li className="flex items-start mt-5 mb-4 ">
                                <IoLocationSharp className="mr-4 text-4xl circle" />
                                <span className='pl-2'>250/385, Padma Complex, 7th Street, Crosscut Road, Coimbatore - 641 012.</span>
                            </li>
                            <li className="flex items-center mb-4">
                                <IoCallSharp className="mr-4 text-2xl" />
                                <span className='pl-2'>+91 99407 12867</span>
                            </li>
                            <li className="flex items-center">
                                <IoMailSharp className="mr-4 text-2xl" />
                                <span className='pl-2'>elegantkitchen.cbe@gmail.com</span>
                            </li>
                        </ul>
                    </div>

                    {/* Column 4: Newsletter */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Newsletter</h4>
                        <p>Contact us today for classy Design Expertise. Best Quality Solutions.</p>
                        <div className="flex mt-4">
                            <input
                                type="email"
                                placeholder="Email here"
                                className="w-full p-3 text-black border border-black outline-none rounded-l-md"
                            />
                            <button className="p-3 text-white bg-black rounded-r-md">
                                <IoIosSend />
                            </button>
                        </div>
                    </div>
                </div >
                <div className="py-4 mx-auto text-center text-white bg-black border-t-2 border-gray-900 mt-14">
                    <p>&copy; 2024 Elegant Kitchen. All rights reserved.</p>
                </div>
            </footer>
            <FloatingWhatsApp
        phoneNumber="+919843110677"  // Your WhatsApp number
        accountName="Elegant Kitchen Customer Support"  // Name that will appear in WhatsApp
        chatMessage="Hi there! How can we help you?"  // Default message
        statusMessage="Online"
        avatar="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"  // Optional: Avatar image URL
      />
        </div>
    );
}

export default FallCeiling;