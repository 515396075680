import { React, useState, useEffect } from 'react';
import logo from '../assets/logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import '../styles.css';
import { FaFacebookF, FaInstagram, FaTwitter, FaPinterestP } from 'react-icons/fa'
import backgroundImage from '../assets/low.jpg';
import { IoLocationSharp } from "react-icons/io5";
import { IoMailSharp } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { IoIosSend } from "react-icons/io";
import one from '../assets/new.jpg';
import one1 from '../assets/77.jpg';
import { FaPhoneAlt } from "react-icons/fa";
import client from '../assets/client.jpg';
import client1 from '../assets/client1.png';
import client3 from '../assets/client3.jpg';
import client2 from '../assets/client2.jpg';
import client4 from '../assets/client4.jpg';
import video from '../assets/video.mp4';
import video1 from '../assets/video1.mp4';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function About() {
    return (
        <>
            <Header />
            <AboutBanner />
            <AboutUs />
            <AboutUs1 />
            <MissionSection />
            <VideoSection />
            <LogoCarousel />
            <Section />
            <Footer />
        </>
    );
}

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false); // To toggle Category dropdown

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleCategory = () => {
        setCategoryOpen(!categoryOpen); // Toggle category dropdown
    };
    return (
        <header className="py-5 bg-white shadow-md">
            <div className="flex items-center justify-between px-5 py-4 mx-auto lg:px-28">
                <div className="w-2/12 max-sm:w-[40vw]">
                    {/* Logo Section */}
                    <img src={logo} alt="Logo" className="h-12 lg:h-16" />
                </div>

                {/* Desktop Navigation Section */}
                <nav className="hidden w-10/12 px-20 py-8 space-x-6 font-semibold text-white bg-black md:flex">
                    <Link to="/" className="hover:text-[#8c2c2c]">Home</Link>
                    <Link to="/about" className="hover:text-[#8c2c2c]">About</Link>

                    {/* Category with Submenu and Dropdown Icon */}
                    <div className="relative group">
                        <button className="menu-link hover:text-[#8c2c2c] flex items-center">
                            Category
                           
                        </button>
                        <div className="absolute hidden text-white bg-black shadow-lg submenu group-hover:block">
                            <Link to="/modular-kitchen" className="font-sans font-semibold text-white submenu-item hover:bg-black hover:text-white">Modular Kitchen</Link>
                            <Link to="/Wardrobe" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wardrobe</Link>
                            <Link to="/pooja-room" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Pooja Room</Link>
                            <Link to="/tv-panel" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Tv Panel</Link>
                            <Link to="/wall-panel" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wall Panel</Link>
                            <Link to="/wall-shelves" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Wall Shelves</Link>
                            <Link to="/show-case" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Show Case</Link>
                            <Link to="/false-ceiling" className="text-gray-700 submenu-item hover:bg-black hover:text-white">False Ceiling</Link>
                            <Link to="/office-interior" className="text-gray-700 submenu-item hover:bg-black hover:text-white">Office Interior</Link>
                        </div>
                    </div>
                    <Link to="/testimonials" className="hover:text-[#8c2c2c]">Testimonials</Link>
                    <Link to="/contact" className="hover:text-[#8c2c2c]">Contact</Link>

                    {/* Social Media Links */}
                    <div className="flex-grow"></div>
                    <div className="flex items-center space-x-3">
                        <a href="https://www.facebook.com/elegantkitchencbe" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://www.instagram.com/elegantkitchencbe/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://x.com/Janarth01930131" target="_blank" rel="noopener noreferrer">
                            <FaTwitter className="text-white hover:text-[#8c2c2c]" />
                        </a>
                        <a href="https://www.pinterest.com/elegantkitchenkovai/" target="_blank" rel="noopener noreferrer">
                            <FaPinterestP className="text-white hover:text-[#8c2c2c]" />
                        </a>
                    </div>
                </nav>

                {/* Mobile Menu Button */}
                <button
                    onClick={toggleMenu}
                    className="p-2 text-black md:hidden lg:text-white">
                    ☰
                </button>
            </div>

            {/* Mobile Navigation */}
            {menuOpen && (
                <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full p-4 text-white bg-black md:hidden">
                    {/* Close Button */}
                    <button
                        onClick={toggleMenu}
                        className="absolute text-3xl text-white top-5 right-5"
                    >
                        ✖
                    </button>
                    <nav className="flex flex-col space-y-6 text-xl text-center">
                        <Link to="/" className="hover:text-gray-400" onClick={toggleMenu}>Home</Link>
                        <Link to="/about" className="hover:text-gray-400" onClick={toggleMenu}>About</Link>

                        {/* Category Dropdown in Mobile View */}
                        <div className="relative">
                            <button
                                className="flex items-center justify-center hover:text-gray-400"
                                onClick={toggleCategory}>
                                Category
                                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                            </button>
                            {categoryOpen && (
                                <div className="mt-2 bg-white items-left">
                                    <Link to="/modular-kitchen" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Modular Kitchen</Link>
                                    <Link to="/Wardrobe" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wardrobe</Link>
                                    <Link to="/pooja-room" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Pooja Room</Link>
                                    <Link to="/tv-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Tv Panel</Link>
                                    <Link to="/wall-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Panel</Link>
                                    <Link to="/wall-shelves" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Shelves</Link>
                                    <Link to="/show-case" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Show Case</Link>
                                    <Link to="/false-ceiling" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>False Ceiling</Link>
                                    <Link to="/office-interior" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Office Interior</Link>
                                </div>
                            )}
                        </div>

                        <Link to="/testimonials" className="hover:text-gray-400" onClick={toggleMenu}>Testimonials</Link>
                        <Link to="/contact" className="hover:text-gray-400" onClick={toggleMenu}>Contact</Link>
                    </nav>
                </div>
            )}
        </header>
    );
}

function AboutBanner() {
    return (
        <section id="about" className="relative bg-center bg-cover h-96 object-fit-cover brightness-200" style={{ backgroundImage: `url(${backgroundImage})` }} >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="container relative flex flex-col justify-center h-full mx-auto text-left items-left">
                <h1 className="text-5xl font-bold text-left text-white">ABOUT US</h1>
                <p className="mt-4 text-white">
                    <a href="/" className="hover:underline">Home</a> &gt; About us
                </p>
            </div>
        </section>
    );
}


function AboutUs() {
    return (
        <section className="relative px-8 py-5 bg-white lg:px-24">
            {/* Background Title */}
            <h2 className="font-bold text-right text-gray-100 text-8xl lg:text-9xl">
                About Us
            </h2>

            {/* Container for Image and Content */}
            <div className="relative z-10 flex flex-col items-center lg:flex-row">
                {/* Image Section */}
                <div className="w-full lg:w-1/2">
                    {/* absolute top-0 left-0 transform -translate-x-1/4 -translate-y-1/3 z-0 */}
                    <img
                        src={one}
                        alt="Modern House Design"
                        className="w-full h-[100vh]  shadow-lg object-cover"
                    />
                </div>

                {/* Content Section */}
                <div className="w-full p-8 mt-8 bg-black shadow-lg lg:w-1/2 lg:mt-0 lg:pl-16">
                    <h3 className="mb-2 text-sm text-white uppercase">About Us</h3>
                    <h1 className="mb-4 text-3xl font-bold leading-tight text-white lg:text-5xl">
                        Elevate Your Space Design <br />
                        Elevate Your Life
                    </h1>
                    <p className="mb-4 text-white">
                        Started in 2009, Elegant Kitchen is a pioneer in the world of modular kitchens, wooden wardrobes, modular wardrobes, fall ceiling designing, and TV panelling in Coimbatore. From 8 years as a market leader in producing the most memorable modular kitchens & interior designing.

                        Our catalogues are stuffed with modern ideas of different sorts that draw inspiration from a variety of local and worldwide influences. They come together to form an inspiring overall design and are packed with a variety of unique designs and features such as overhead storage modules, extended breakfast counters, kitchen cabinets, countertops, pantry units, and built-in appliance units that give the entire space a diversified look.
                    </p>


                </div>
            </div>
        </section>
    );
}
function AboutUs1() {
    return (
        <section className="relative px-8 py-5 bg-white lg:px-24">
            {/* Background Title */}
            {/* <h2 className="font-bold text-left text-gray-100 text-8xl lg:text-9xl">
              Our Services
            </h2> */}

            {/* Container for Image and Content */}
            <div className="relative z-10 flex flex-col items-center lg:flex-row">
                {/* Content Section */}
                <div className="w-full p-8 mt-8 mb-8 bg-black shadow-lg lg:w-1/2 lg:mt-0 lg:pr-16">

                    <div className="flex items-center text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-green-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="ml-2">We also deal with Branded ISI / ISO 9001 (Stainless Steel 304 Grade) <br></br>kitchen trolleys & Racks.</span>
                    </div>
                    <div className="flex items-center pt-4 text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-green-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="ml-2">We offer Designing, Manufacturing and Facility Management turnkey <br></br>fit-out services & Solutions customizing them according to your preferences.</span>
                    </div>
                    <div className="flex items-center pt-4 text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-green-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="ml-2">We deal with an extensive range of interior products and services <br></br>viz: Modular kitchens and Wardrobes, Wood carvings, Pooja house designs and TV panels.</span>
                    </div>
                    <div className="flex items-center pt-4 text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-green-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="ml-2">We always provide the best-in-class services and installation in this <br></br>industry based on your needs, lifestyle, and current standards.</span>
                    </div>
                    <div className="flex items-center pt-4 text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-green-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="ml-2">We also deal with Branded ISI / ISO 9001 (Stainless Steel 304 Grade)  kitchen  Accessories.</span>
                    </div>
                    <div className="flex items-center pt-4 text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-green-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="ml-2">We deal  with Kitchen Accessories from</span>
                    </div>
                </div>

                {/* Image Section */}
                <div className="w-full lg:w-1/2">
                    <img
                        src={one1}
                        alt="Service Image"
                        className="w-full h-[90vh] shadow-lg object-cover"
                    />
                </div>
            </div>
        </section>
    );
}

function MissionSection() {
    return (
        <section className="py-16 ">
            <div className="container relative flex flex-wrap mx-auto bg-center bg-cover justify-left" style={{ backgroundImage: `url(${one})` }}>
                {/* Form Section */}
                <div className="relative z-10 w-full p-8 bg-white lg:w-12/12 lg:mb-0 bg-opacity-90 ">
                    <div className="flex flex-wrap ">
                        <div className="items-center px-4 lg:w-1/3 justify-left">
                            <IoLocationSharp className="mr-4 text-2xl text-white" />
                            <div>
                                <p className="font-sans text-2xl font-bold ">Mission & Vision</p><br />
                                <p>
                                    To be a one-stop shop for all needs and demands related to Kitchen Designing, Wardrobe & Interior Designing & Manufacturing.

                                    <br></br>At ‘Elegant Kitchen’ our joint mission is to bring joy to our customers attaining the highest levels of customer satisfaction and delight.
                                </p>
                            </div>
                        </div>
                        <div className="px-4 lg:w-1/3">
                            <IoLocationSharp className="mr-4 text-2xl text-white" />
                            <div>
                                <p className="font-sans text-2xl font-bold ">Team</p><br />
                                <p>
                                    Elegant Kitchen has a solid and professional staff of architects, interior designers, project engineers, and technicians led by (Founder’s name here). He has built the best-in-class craftsmanship and has a supportive team. His team of skilled design specialists advise and helps you throughout the creative process of making your vision into reality.

                                    We as one team take care of everything, from concept to execution as a living area, pooja rooms or your cooking area!   </p>
                            </div>
                        </div>
                        <div className="px-4 lg:w-1/3">
                            <IoLocationSharp className="mr-4 text-2xl text-white" />
                            <div>
                                <p className="font-sans text-2xl font-bold ">Quality</p><br />
                                <p>
                                    Ensuring great quality to find the perfect beautifying décor for your home.</p>
                                <li>High-strength</li>
                                <li>Hygienic</li>
                                <li>Highly durable</li>
                                <li>Easy to clean</li>
                                <li>Aesthetic & Enhancing</li>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </section >
    );
}function VideoSection() {
    return (
        <section className="py-16">
           
            <div className="container px-4 mx-auto">
            <h2 className="font-bold text-right text-gray-100 text-8xl lg:text-8xl">
                Our Work In Action
            </h2>
                <div className="grid grid-cols-1 gap-8 mt-10 lg:grid-cols-2">
                    {/* Video 1 */}
                    <div className="w-full rounded-lg shadow-lg">
                        <video className="w-full h-[50vh] rounded-lg shadow-lg" controls>
                            <source src={video1} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* Video 2 */}
                    <div className="w-full rounded-lg shadow-lg">
                        <video className="w-full h-[50vh] rounded-lg shadow-lg" controls>
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </section>
    );
}

function LogoCarousel() {
    const logos = [
        { id: 1, src: client },
        { id: 2, src: client1 },
        { id: 3, src: client3 },
        { id: 4, src: client2 },
        { id: 5, src: client4 },
    ];

    return (
        <section className="object-cover py-16 overflow-hidden bg-black lg:px-72">
            <div className="relative flex items-center justify-around animate-slide">
                {logos.map(logo => (
                    <img key={logo.id} src={logo.src} alt={`Logo ${logo.id}`} className="lg:h-[12vh] sm:h-10 mx-20" />
                ))}
                {logos.map(logo => (
                    <img key={logo.id + 5} src={logo.src} alt={`Logo ${logo.id}`} className="lg:h-[12vh] sm:h-10 mx-20" />
                ))}
            </div>
        </section>
    );
}
function Section() {
    return (
        <section className="relative px-8 py-5 bg-white lg:px-24">
            {/* Background Title */}
            <h2 className="text-5xl font-bold text-right text-gray-200 lg:text-5xl">
                Book your consultation today<br className="py-4"></br>+91 99407 12867, +91 98431 10677

            </h2>

        </section>
    )
}

function Footer() {
    return (
        <div>

            {/* Footer Section */}
            <footer className="text-white bg-black ">
                <div className="grid grid-cols-1 gap-8 pt-10 mx-10 lg:grid-cols-4 md:grid-cols-3">
                    {/* Column 1: Logo and Description */}
                    <div>
                        <img src={logo} alt="Logo" className="h-20 lg:mr-24 md:mr-4 " />
                        <p className='pt-5 font-sans font-medium'>We welcome you to our wonderful world of Interior and Furniture.</p>
                    </div>

                    {/* Column 2: Pages Links */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Useful Links</h4>
                        <ul>
                            <li><a href="#" className="pb-10 font-sans font-normal hover:underline ">About Us</a></li><br></br>
                            <li><a href="#" className="pb-2 font-sans font-normal hover:underline">Testimonial</a></li><br></br>
                            <li><a href="/contact" className="pb-2 font-sans font-normal hover:underline">Contact</a></li>
                        </ul>
                    </div>

                    {/* Column 3: Office Information */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Contact Us</h4>
                        <ul>
                            <li className="flex items-start mt-5 mb-4 ">
                                <IoLocationSharp className="mr-4 text-4xl circle" />
                                <span className='pl-2'>250/385, Padma Complex, 7th Street, Crosscut Road, Coimbatore - 641 012.</span>
                            </li>
                            <li className="flex items-center mb-4">
                                <IoCallSharp className="mr-4 text-2xl" />
                                <span className='pl-2'>+91 99407 12867</span>
                            </li>
                            <li className="flex items-center">
                                <IoMailSharp className="mr-4 text-2xl" />
                                <span className='pl-2'>elegantkitchen.cbe@gmail.com</span>
                            </li>
                        </ul>
                    </div>

                    {/* Column 4: Newsletter */}
                    <div>
                        <h4 className="mb-4 font-sans text-lg font-semibold">Newsletter</h4>
                        <p>Contact us today for classy Design Expertise. Best Quality Solutions.</p>
                        <div className="flex mt-4">
                            <input
                                type="email"
                                placeholder="Email here"
                                className="w-full p-3 text-black border border-black outline-none rounded-l-md"
                            />
                            <button className="p-3 text-white bg-black rounded-r-md">
                                <IoIosSend />
                            </button>
                        </div>
                    </div>
                </div >
                <div className="py-4 mx-auto text-center text-white bg-black border-t-2 border-gray-900 mt-14">
                    <p>&copy; 2024 Elegant Kitchen. All rights reserved.</p>
                </div>
            </footer>
            <FloatingWhatsApp
        phoneNumber="+919843110677"  // Your WhatsApp number
        accountName="Elegant Kitchen Customer Support"  // Name that will appear in WhatsApp
        chatMessage="Hi there! How can we help you?"  // Default message
        statusMessage="Online"
        avatar="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"  // Optional: Avatar image URL
      />
        </div>
    );
}

export default About;